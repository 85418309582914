import service from "../util/link"
import url from "../util/apiurl"

/**
 * 设备管理请求
 */
export function equipment() {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.equipment,
            method: "get",

        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function seachid(obj) {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.equipment,
            method: "get",
            params: {
                companyid: obj.companyid,
                device_id:obj.device_id
            }
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function addcode(obj) {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.equipment,
            method: "post",
            data: obj
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function eqmpages(obj) {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.equipment,
            method: "get",
             params: {
                 page: obj.page,
                 companyid: obj.companyid,
                device_id:obj.device_id
             }
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function editeqm(obj) {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.equipment,
            method: "put",
           data:obj
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function exchangeqm(obj) {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.exchangeqm,
            method: "put",
           data:obj
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function eqmdelete(obj) {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.equipment,
            method: "delete",
            data: obj
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function addeqmtype(obj) {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.addeqmtype,
            method: "post",
            data: obj
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}