<template>
  <div class="eqmtable">
    <el-row :gutter="0" class="search" type="flex" align="middle">
      <el-col v-if="username !== '交控创新中心'" :span="2" class="search_item"
        ><el-button
          style="backgroundcolor: #0076f6;margin-right:10px"
          type="primary"
          @click="addeqm()"
          >设备添加</el-button
        ></el-col
      >
      <el-col v-if="username !== '交控创新中心'" :span="2" class="search_item2"
        ><el-button
          style="backgroundcolor: #0076f6;margin-right:10px"
          type="primary"
          @click="excheqm()"
          >批量变更</el-button
        ></el-col
      >
      <el-col v-if="username !== '交控创新中心' && userRoleId === '1'" :span="2" class="search_item3">
        <el-button
          style="backgroundcolor: #0076f6"
          type="primary"
          @click="eqmtype()"
          >添加型号</el-button
        ></el-col
      >
      <el-col :span="2" :offset="12" class="search_item">设备所属：</el-col>
      <el-col :span="3" class="search_item"
        ><el-select
          v-model="id"
          clearable
          @change="selectcode($event)"
          placeholder="所属公司"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.code"
            :value="item.id"
          >
          </el-option> </el-select
      ></el-col>
      <el-col :span="2" class="search_item">设备ID：</el-col>
      <el-col :span="3" class="search_item">
        <el-input  v-model="eqmid"   placeholder="设备编号"></el-input>
      </el-col>
      <el-col :span="2" class="search_item"
        ><el-button
          style="backgroundcolor: #0076f6"
          type="primary"
          @click="query()"
          >查询</el-button
        ></el-col
      >
    </el-row>

    <el-table
      ref="multipleTable"
      :data="tableData"
      border
      tooltip-effect="dark"
      align="center"
      stripe
      style="color: #161718"
      max-height="670"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>

      <el-table-column prop="code" label="设备编号" align="center" width="300">
      </el-table-column>
      <el-table-column
        prop="version"
        label="设备型号"
        align="center"
        width="200"
      >
      </el-table-column>
      <el-table-column
        prop="company"
        label="所属公司"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        v-if="username !== '交控创新中心'"
        label="操作"
        align="center"
        fixed="right"
        width="200"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            @click="handleEdit(scope.row)"
            style="margin-right: 14px"
            >编辑</el-button
          >
          <el-popconfirm
            confirmButtonText="好的"
            cancelButtonText="不用了"
            icon="el-icon-info"
            iconColor="red"
            title="确认删除该设备吗？"
            @onConfirm="handleDelete(scope.row)"
          >
            <el-button size="mini" type="danger" slot="reference"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <el-row class="paging" align="middle" type="flex" justify="end">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="pagesize"
        layout="prev,total, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-row>
    <!-- 添加设备信息框 -->
    <el-dialog
      @closed="addclose"
      ref="addeqm"
      title="添加设备"
      :visible.sync="dialogFormVisible"
      width="30%"
    >
      <el-form :model="insertform" :rules="addrules" ref="insertform">
        <el-form-item
          label="设备编码"
          :label-width="formLabelWidth"
          prop="code"
        >
          <el-input v-model="insertform.code" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="设备型号"
          :label-width="formLabelWidth"
          prop="version"
        >
          <el-select
            v-model="insertform.version"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in versionops"
              :key="item.version_id"
              :label="item.version"
              :value="item.version_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="公司账号"
          :label-width="formLabelWidth"
          prop="addid"
        >
          <el-select
            v-model="insertform.addid"
            clearable
            @change="selectcompany($event)"
            placeholder="请选择设备所属公司"
          >
            <el-option
              v-for="item in addoptions"
              :key="item.id"
              :label="item.code"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            style="backgroundcolor: #0076f6"
            @click="insertcancel('insertform')"
            >重置</el-button
          >
          <el-button
            style="backgroundcolor: #0076f6"
            type="primary"
            @click="inserteqm('insertform')"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 编辑设备信息 -->
    <el-dialog
      @closed="editclose"
      title="编辑设备信息"
      :visible.sync="editDialogVisible"
      width="30%"
    >
      <el-form ref="editform" :model="editform">
        <el-form-item
          label="设备编码"
          :label-width="formLabelWidth"
          prop="code"
        >
          <el-input
            v-model="editform.code"
            autocomplete="off"
            placeholder="请输入设备编号"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item
          label="设备型号"
          :label-width="formLabelWidth"
          prop="version"
        >
          <el-select
            v-model="editform.version"
            @change="changeversion($event)"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in editversionops"
              :key="item.version_id"
              :label="item.version"
              :value="item.version_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="所属公司"
          :label-width="formLabelWidth"
          prop="editid"
        >
          <el-select
            v-model="editform.editid"
            clearable
            @change="selectcompany($event)"
            placeholder="变更所属公司"
          >
            <el-option
              v-for="item in editoptions"
              :key="item.id"
              :label="item.code"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="editcancel('insertform')">重置</el-button>
          <el-button type="primary" @click="conediteqm()">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 批量变更 -->
    <el-dialog
      @closed="exchangeclose"
      title="批量变更设备"
      :visible.sync="dialogexchange"
      width="30%"
    >
      <el-form :model="exchangform">
        <el-form-item label="所选设备:" :label-width="formLabelWidth">
          <el-tag
            v-for="tag in exchangform.selected"
            :key="tag"
            closable
            :disable-transitions="true"
            :type="tag.type"
            effect="dark"
            @close="removetag(tag)"
          >
            <span class="tags">{{ tag }}</span>
          </el-tag>
        </el-form-item>
        <el-form-item label="将设备变更至:" :label-width="formLabelWidth">
          <el-select
            v-model="exchangform.exchangtoid"
            clearable
            @change="selectcompany($event)"
            placeholder="请选择设备所属公司"
          >
            <el-option
              v-for="item in exchangoptions"
              :key="item.id"
              :label="item.code"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogexchange = false">取 消</el-button>
        <el-button type="primary" @click="confirmexchang()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加设备型号 -->
    <el-dialog
      title="添加设备型号"
      :visible.sync="eqmtypeDialogVisible"
      width="30%"
    >
      <el-form ref="addeqmtype" :model="eqmtypeform">
        <el-form-item label="型号" :label-width="eqmtypeLabelWidth">
          <el-input v-model="eqmtypeform.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="功能" :label-width="eqmtypeLabelWidth">
          <el-checkbox v-model="eqmtypeform.checked">视频功能</el-checkbox>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="addeqmtype()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  equipment,
  seachid,
  addcode,
  eqmpages,
  exchangeqm,
  editeqm,
  eqmdelete,
  addeqmtype,
} from "../../api/equipmentapi";
export default {
  data() {
    return {
      eqmrule: [
        {
            min: 3,
            max: 22,
            message: "长度在 3 到 22 个字符",
            trigger: "blur",
          },
      ],

      eqmid: "",
      username: "",
      /**添加设备型号 */

      eqmtypeform: {
        name: "",
        checked: false,
      },
      exchangform: {
        selected: [],
        exchangtoid: "",
      },
      exchangoptions: [
        {
          id: 1,
          code: "无数据",
        },
      ],

      options: [
        {
          id: 1,
          code: "无数据",
        },
      ],
      id: "",
      addoptions: [
        {
          id: 1,
          code: "无数据",
        },
      ],
      /**添加设备型号 */
      versionops: [
        {
          version_id: 1,
          version: "无数据",
        },
      ],
      version_id: "",
      /**编辑设备 */
      editversionops: [
        {
          version_id: 1,
          version: "无数据",
        },
      ],
      version_id: "",
      insertform: {
        code: "",
        version: "",
        addid: "",
      },
      editform: {
        code: " ",
        version: "",
        editid: "",
        version_id: "",
      },
      editcodeid: "",
      editoptions: [
        {
          id: 1,
          code: "无数据",
        },
      ],
      editrules: {
        code: [
          { required: true, message: "请输入设备编号", trigger: "blur" },
          {
            min: 3,
            max: 22,
            message: "长度在 3 到 22 个字符",
            trigger: "blur",
          },
        ],
        version: [
          { required: true, message: "请选择设备型号", trigger: "change" },
        ],
        addid: [
          { required: true, message: "请选择设备所属公司", trigger: "change" },
        ],
      },
      addrules: {
        code: [
          { required: true, message: "请输入设备编号", trigger: "blur" },
          {
            min: 3,
            max: 22,
            message: "长度在 3 到 22 个字符",
            trigger: "blur",
          },
        ],
        version: [
          { required: true, message: "请选择设备型号", trigger: "change" },
        ],
        addid: [
          { required: true, message: "请选择设备所属公司", trigger: "change" },
        ],
      },
      tableData: [],
      dialogFormVisible: false,
      editDialogVisible: false,
      eqmtypeDialogVisible: false,
      dialogexchange: false,
      formLabelWidth: "120px",
      eqmtypeLabelWidth: "80px",
      currentPage3: 1,
      pagesize: 12,
      total: 32,
      multipleSelection: [],
      companyarry: [],
      userRoleId:null,
    };
  },
  mounted() {
    this.username = this.$cookies.get("user");
    this.getequipment();
  },
  created() {
    this.userRoleId = this.$cookies.get("userRoleId");
  },
  methods: {
    query() {
      let param = {
        companyid: this.id,
        device_id: this.eqmid,
      };
      seachid(param).then((res) => {
        console.log(res);
        this.options = res.data.codes;
        this.tableData = res.data.datas;
        this.pagesize = res.data.page_size;
        this.total = res.data.total_pages;
      });
    },

    /**获取设备信息列表 */
    getequipment() {
      equipment()
        .then((res) => {
          console.log(res);
          console.log("设备页");
          this.options = res.data.codes;
          this.tableData = res.data.datas;
          this.versionops = res.data.version;
          this.pagesize = res.data.page_size;
          this.total = res.data.total_pages;
        })
        .catch((e) => {});
    },
    /**选择设备所属 */
    selectcode(e) {
      // this.currentPage3=1
      // if (e !== "") {
      //   seachid(e)
      //     .then((res) => {
      //       console.log(res);
      //       this.options = res.data.codes;
      //       this.tableData = res.data.datas;
      //       this.pagesize = res.data.page_size;
      //       this.total = res.data.total_pages;
      //     })
      //     .catch(() => {});
      // } else {
      //   this.getequipment();
      // }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    /**切换下一页 */
    handleCurrentChange(val) {
      let param = {
        page: val,
        companyid: this.id,
        device_id: this.eqmid,
      };
      eqmpages(param)
        .then((res) => {
          if (res.status == 200) {
            console.log(res);
            this.tableData = res.data.datas;
            this.pagesize = res.data.page_size;
            this.total = res.data.total_pages;
          }
        })
        .catch(() => {
          this.$message.error(e.message);
        });
      console.log(`当前页: ${val}`);
    },
    /**添加设备型号 */
    eqmtype() {
      this.eqmtypeDialogVisible = true;
    },
    addeqmtype() {
      if (this.eqmtypeform.name) {
        var param = new URLSearchParams();
        param.append("version", this.eqmtypeform.name);
        param.append("have_video", this.eqmtypeform.checked ? 1 : 0);
        addeqmtype(param)
          .then((res) => {
            console.log(res);
            if (res.status == 200) {
              this.$message({
                type: "success",
                message: res.data.message,
              });
              this.$refs.addeqmtype.resetFields();
              this.eqmtypeDialogVisible = false;
              this.getequipment();
            } else {
              this.$message({
                type: "warning",
                message: res.data.message,
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    /**选择设备 */
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
      console.log("这是选择行信息");
    },
    /**添加设备 */
    addeqm() {
      this.dialogFormVisible = true;
      this.addoptions = this.options;
    },
    inserteqm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.insertform.code);
          console.log(this.insertform.version);

          console.log(this.insertform.addid);
          var param = new URLSearchParams();
          param.append("code", this.insertform.code);
          param.append("version", this.insertform.version);
          console.log(this.insertform.version, "vididid");
          param.append("companyid", this.insertform.addid);
          addcode(param)
            .then((res) => {
              console.log(res);
              if (res.status == 200) {
                this.$message({
                  type: "success",
                  message: res.data.message,
                });
                this.insertform.code = "";
                this.insertform.version = " ";
                this.insertform.addid = " ";
                this.dialogFormVisible = false;
                this.getequipment();
              }
            })
            .catch((e) => {
              console.log(e);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**重置 */
    insertcancel(formName) {
      this.$refs[formName].resetFields();
    },
    /**编辑 */
    handleEdit(row) {
      console.log(row);
      console.log(this.editform);

      this.editcodeid = row.id;
      this.editform.code = row.code;
      this.editform.version = row.version;
      this.editform.version_id = row.version_id;
      this.editform.editid = row.company_id;
      this.editversionops = this.versionops;
      this.editoptions = this.options;
      console.log("编辑操作");

      this.editDialogVisible = true;
    },
    excheqm() {
      if (this.multipleSelection.length > 0) {
        let elementsAreEqual = (array) => array.every((el) => el === array[0]);
        this.multipleSelection.forEach((e) => {
          this.companyarry.push(e.company);
        });
        console.log(elementsAreEqual(this.companyarry));
        if (elementsAreEqual(this.companyarry) == true) {
          this.dialogexchange = true;
          this.multipleSelection.forEach((e) => {
            this.exchangform.selected.push(e.code);
          });
          console.log(this.multipleSelection[0].company);
          console.log(this.options);
          console.log("俩个数组");
          let newarr = [];
          for (let i = 0; i < this.options.length; i++) {
            if (this.options[i].code !== this.multipleSelection[0].company) {
              newarr.push(this.options[i]);
            }
          }
          console.log(newarr);
          console.log("新数组");
          this.exchangoptions = newarr;
        } else {
          this.$message({
            type: "warning",
            message: "设备需为同属一家公司",
          });
          this.$refs.multipleTable.clearSelection();
          this.companyarry.length = 0;
          this.exchangform.selected.length = 0;
        }
        console.log("表格设备");
      } else {
        this.$message({
          type: "warning",
          message: "请选择所要变更的设备",
        });
      }
    },
    /**确认批量变更 */
    confirmexchang() {
      if (
        this.exchangform.selected.length > 0 &&
        this.exchangform.exchangtoid != ""
      ) {
        console.log(this.exchangform.selected);
        console.log(this.exchangform.exchangtoid);
        var param = new URLSearchParams();
        param.append("ids", this.exchangform.selected);
        param.append("accountid", this.exchangform.exchangtoid);
        exchangeqm(param)
          .then((res) => {
            if ((res.data.code = 200)) {
              this.$message({
                type: "success",
                message: "设备批量变更成功",
              });
              this.exchangform.selected.length = 0;
              this.exchangform.exchangtoid = "";
              this.dialogexchange = false;

              this.$refs.multipleTable.clearSelection();
              this.getequipment();
            } else {
            }
            // this.tableData = res.data.datas;
            // this.pagesize = res.data.page_size;
            // this.total = res.data.total_pages;
          })
          .catch((e) => {});
      } else {
        this.$message({
          type: "warning",
          message: "请选择公司名称",
        });
      }
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    removetag(tag) {
      const item = this.exchangform.selected.indexOf(tag);
      this.exchangform.selected.splice(item, 1);
    },
    exchangeclose() {
      this.exchangform.selected.length = 0;
    },
    /*编辑确认 */
    conediteqm() {
      console.log("执行编辑");
      var param = new URLSearchParams();
      param.append("id", this.editcodeid);
      param.append("code", this.editform.code);
      param.append("version", this.editform.version_id);
      console.log(this.editform.version_id, "vsid1111");
      param.append("companyid", this.editform.editid);
      editeqm(param)
        .then((res) => {
          if ((res.data.code = 200)) {
            console.log(res);

            this.getequipment();
            this.editDialogVisible = false;
          } else {
          }
          // this.tableData = res.data.datas;
          // this.pagesize = res.data.page_size;
          // this.total = res.data.total_pages;
        })
        .catch((e) => {});
    },
    handleDelete(row) {
      console.log(row);
      console.log("这是row");
      var param = new URLSearchParams();
      param.append("id", row.id);
      eqmdelete(param).then((res) => {
        if ((res.data.code = 200)) {
          console.log(res);
          this.$message({
            type: "success",
            message: "设备删除成功",
          });

          this.getequipment();
        } else {
        }
        // this.tableData = res.data.datas;
        // this.pagesize = res.data.page_size;
        // this.total = res.data.total_pages;
      });
    },
    addclose() {},
    editclose() {},
    selectcompany(e) {
      this.editform.editid = e;
    },
    changeversion(e) {
      console.log(e, "型号事件");
      this.editform.version_id = e;
    },
    insertconfirm() {},
  },
};
</script>

<style scoped>
@media only screen and (max-width: 1920px) {
  .eqmtable {
    padding: 1.25rem 1.875rem 0 1.875rem;
  }
  .paging {
    width: 70%;
    float: right;
    /* background-color: chocolate; */
    text-align: end;
    margin-top: 1.25rem;
  }
  .search {
    text-align: center;

    /* border: 0.0625rem solid black; */
    background-color: #ebeff5;
    border-radius: 0.625rem;
    padding: 0.5rem 0.625rem 0.5rem 0.625rem;
    font-size: 0.875rem;
    color: #161718;
  }
  .search_item {
    /* background-color: darkturquoise; */
  }
  .el-tag {
    margin: 0.1875rem;
  }
  .el-table {
    margin-top: 1.25rem;
  }
}
@media only screen and (max-width: 1080px) {
  .eqmtable {
    padding: 0.625rem 0.625rem 0 0.625rem;
  }
  .paging {
    width: 70%;
    float: right;
    /* background-color: chocolate; */
    text-align: end;
    margin-top: 1.25rem;
  }
  .search {
    text-align: center;

    /* border: 0.0625rem solid black; */
    background-color: #ebeff5;
    border-radius: 0.625rem;
    padding: 0.5rem 0.625rem 0.5rem 0.625rem;
    font-size: 0.875rem;
    color: #161718;
  }
  .search .search_item2,
  .search .search_item3 {
    /* margin-left: 3.125rem; */
  }
  .search_item {
    /* background-color: darkturquoise; */
  }
  .el-tag {
    margin: 0.1875rem;
  }
  .el-table {
    margin-top: 0.625rem;
  }
}
</style>